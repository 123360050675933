<script>
  import store from "../../store/index.js";
  import VueSlickCarousel from "vue-slick-carousel";
  import SignupButton from "./SignupButton.vue";
  import { Carousel3d, Slide } from "vue-carousel-3d";
  import { mapGetters } from "vuex"
  export default {
    components: { VueSlickCarousel, SignupButton, Carousel3d, Slide },
    props: {
      title: String,
      moreTitle: String,
      moreTo: String,
      products: Array,
      cat_id: String,
      isInView: Boolean,
    },
    data() {
      return {
        info: null,
        locale: "",
        slideIndex: 0,
        isMore: true,
        keyCarousel: 10,
        elmkey: 0,
        scrolled: false,
      };
    },
    computed: {
      ...mapGetters(["newLocale"]),
      localeLang(){
        return this.newLocale || {}
      },
      initData: async function () {
        await this.getLanguage();
      },
      settings() {
        return {
          width: 650,
          height: 350,
          autoplayTimeout: 6000,
          autoplay: this.isInView,
        };
      },
    },
    watch: {
      isInView() {
        this.elmkey++;
      },
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getSignUpText() {
        return this.info?.btnSignupText;
      },
      updateDotsSlider() {
        setTimeout(() => {
          document.querySelectorAll(".event-desktop-container-slide").forEach((item, i) => {
            if (item.classList.contains("current")) {
              this.slideIndex = i;
            }
          });
        }, 480);
      },
      changeSlide(index) {
        this.slideIndex = index;
        this.keyCarousel++;
      },
      onSlideChange() {
        this.updateDotsSlider();
      },
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let { testimonials } = await store.getters["locale"];
          this.locale = testimonials;
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.news;
        }
      },
      urlEncodeB64: function (url) {
        return btoa(url);
      },

      tgTestimonial(index, text) {
        this.focusView();
        this.isMore = !this.isMore;
        const elms = document.querySelectorAll(`div[presentsection=carouselText${index}]`);
        for (const elm of elms) {
          elm.innerText = this.getTextSize(text, 380, index);
        }
      },
      getTextSize(text, limit, index) {
        if (this.isMore) {
          return this.$global.getShort(text, limit);
        }
        return text;
      },

      handleSwipe(event, slick, currentSlide) {
        const allCarousel = [];
        if (!this.isMore) {
          this.focusView();
          if (!this.isMore) {
            this.$router.push("/#present");
          }
          this.isMore = true;
          for (let i = 0; i < this.info.news.length; i++) {
            allCarousel.push(document.querySelector(`div[presentsection=carouselText${i}]`));
          }
          for (const text of allCarousel) {
            text.innerText = this.$global.getShort(text.innerText, 380);
          }
        }
      },
      focusView() {
        if (!this.isMore) {
          this.$router.push("/#present").catch(() => {});
        }
      },
      takeAfter(e) {
        let current = document.querySelector(".section-desktop2 .slick-current");
        current.classList.add("curr");
        let prev1 = current.previousSibling;
        let prev2 = prev1.previousSibling;
        let next1 = current.nextSibling;
        let next2 = next1.nextSibling;
        this.addUniqueClass(prev1, "prev1");
        this.addUniqueClass(prev2, "prev2");
        this.addUniqueClass(next1, "next1");
        this.addUniqueClass(next2, "next2");
      },
      addUniqueClass(ele, cl) {
        let allCards = document.querySelectorAll(`.${cl}`);
        allCards.forEach((element) => {
          element.classList.remove(cl);
        });
        ele.classList.add(cl);
      },
      handleScroll() {
        if (!this.scrolled) {
          this.elmkey++;
          this.scrolled = true;
          window.removeEventListener("scroll", this.handleScroll);
        }
      },
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  };
</script>

<template>
  <section class="event-desktop" id="films" v-bind="initData">
    <div class="event-desktop-container" v-if="info" @mouseup="updateDotsSlider" :key="elmkey">
      <h2 class="event-desktop-title sec-font main-title" bold>{{ info.title }}</h2>
      <carousel-3d class="event-desktop-carousel" v-bind="settings" :start-index="slideIndex" :key="keyCarousel" @after-slide-change="onSlideChange">
        <slide class="event-desktop-container-slide" v-for="(post, idx) in info.news" :key="idx" :index="idx">
          <router-link class="event-desktop-container-info" :to="slideIndex == idx ? { path: '/watch-text', query: { datapost: post.name } } : {}" block>
            <div class="event-desktop-container-image">
              <div class="wrapper-btn"><img class="btn-play" src="../../assets/img/btn-play.svg" :alt="localeLang.alt.btnPlay" /></div>
              <img class="event-desktop-image" :src="urlCloudFront(post.pictureLink)" :alt=" post.alt ? post.alt : post.title" prevent block loading="lazy" />
            </div>
            <div :class="`event-desktop-text ${post.isNewVideo ? 'section-new' : ''}`">
              <h3 class="event-desktop-sub-title sec-font sub">{{ post.title }}</h3>
              <p class="event-desktop-description">
                {{ getTextSize(post.describe, 340, idx) }}
              </p>
              <span class="icon-view-more"> </span>
            </div>
          </router-link>
          <p class="event-desktop-counter">{{ idx + 1 }}/{{ info.news.length }}</p>
        </slide>
      </carousel-3d>
      <ul class="slide-dots">
        <li :class="`slide-dot ${i == slideIndex ? 'slide-active' : ''}`" v-for="(slide, i) in info.news" @click="changeSlide(i)" :key="i">
          <button>{{ i }}</button>
        </li>
      </ul>
    </div>
    <div v-if="getSignUpText()" class="">
      <signup-button class="event-desktop-signup" :title="getSignUpText()"></signup-button>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  button {
    border: none;
    cursor: pointer;
  }
  .event-desktop {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    &-counter {
      position: absolute;
      bottom: 5px;
      left: 10px;
      color: #fff;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 1px 8px;
      border-radius: $radius-12;
    }
    &-container {
      .event-desktop-title {
        color: #bd0909;
        font-size: 42px;
        font-weight: normal;
      }
      .event-desktop-carousel {
        overflow: hidden;
        padding: 10px 0;
        height: 380px !important;
        .event-desktop-container-slide {
          border-radius: 11px;
          border: none;
          background-color: #f6f6f6;
          box-shadow: $space-8 $space-4 $space-8 rgba(0, 0, 0, 0.25);
          .event-desktop-container-info {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .event-desktop-container-image {
              width: 100%;
              .wrapper-btn {
                width: 70px;
                height: 70px;
                background: $primary_color;
                border-radius: 50%;
                position: absolute;
                left: 32%;
                top: 50%;
                transform: translate(-50%, -50%);
                .btn-play {
                  width: 50%;
                  position: absolute;
                  left: 55%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              .event-desktop-image {
                height: 353px;
                width: 100%;
                object-fit: cover;
                border-bottom-left-radius: 11px;
                border-top-left-radius: 11px;
              }
            }

            .event-desktop-text {
              width: 60%;
              height: 100%;
              padding: 15px 20px;
              position: relative;
              .event-desktop-sub-title {
                color: #bd0909;
                font-size: 20px;
                font-weight: normal;
                margin-bottom: 10px;
              }
              .event-desktop-description {
                font-size: 14px;
                text-align: justify;
              }
              .icon-view-more {
                bottom: 5px;
                right: 5px;
                width: 30px;
                height: 30px;
                position: absolute;
                background: url("../../assets/img/icons/icon-view.svg");
                background-repeat: no-repeat;
              }
              &::after {
                position: absolute;
                visibility: hidden;
                top: 25px;
                right: -26px;
                content: "New";
                display: flex;
                justify-content: center;
                font-size: 10px;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                line-height: 20px;
                transform: rotate(45deg);
                width: 130px;
                background: $primary_color;
                box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
              }
              &.section-new {
                &::after {
                  visibility: visible;
                }
              }
              &.section-new {
                position: relative;
              }
            }
          }
        }
      }
    }
    &-signup {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 218px;
      height: 40px;
      border-radius: 22px;
      margin: 0 auto;
    }

    .event-desktop-container-slide:not(.current) .event-desktop-container-image {
      pointer-events: none;
    }

    .right-2,
    .right-1 {
      .event-desktop-container-info {
        flex-direction: row-reverse;
      }
      .event-desktop-counter {
        position: absolute;
        bottom: 5px;
        right: 10px;
        color: #fff;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 1px 10px;
        border-radius: $radius-12;
        transform: translateX(575px);
        width: max-content;
      }
    }

    .left-1,
    .left-2 {
      .wrapper-btn {
        display: none;
      }
    }
    .left-1 {
      transform: translateX(-365px) translateZ(-150px) scale(0.9) !important;
    }
    .left-2 {
      transform: translateX(-750px) translateZ(-300px) scale(0.8) !important;
    }
    .right-1 {
      transform: translateX(365px) translateZ(-150px) scale(0.9) !important;
    }
    .right-2 {
      transform: translateX(750px) translateZ(-300px) scale(0.8) !important;
    }
    @include Mobile {
      .slide-dots {
        display: none;
      }
    }
    .slide-dots {
      bottom: $mpadding;
      display: flex;
      justify-content: center;
      margin: 20px;
    }
    .slide-dots {
      margin-right: 10px;
    }
    .slide-dots li button {
      font-size: 15px;
      color: $primary_color;
    }

    .slide-dots li button {
      color: transparent;
      width: 20px;
      height: 10px;
      border-radius: 10px;
      background-color: #d9d9d980;
    }

    .slide-dots li.slide-active button {
      background-color: $primary_color;
    }

    .slide-dots li button:before {
      color: transparent;
      opacity: 0;
    }
    .slide-dots {
      bottom: $mpadding * 3;
    }
    .slide-dots li {
      margin: 0 15px !important;
    }
    .slide-dots li button {
      width: 29px;
    }

    @media screen and (max-width: 1023px) {
      .slide-dots li {
        margin: $space-0 $space-8 !important;
      }
    }
  }
</style>
