<script>
  import VueSlickCarouselc from "vue-slick-carousel";
  import store from "../../store/index.js";
  import { mapActions } from "vuex";

  export default {
    components: { VueSlickCarouselc },
    props: { openSelf: { type: Boolean, default: false } },
    data: function () {
      return {
        data: null,
        language: null,
        lang: "es",
        categories: null,
        pictures: null,
        settings: {
          centerMode: true,
          clickToScroll: false,
          centerPadding: "20px",
          focusOnSelect: true,
          arrows: true,
          dots: false,
          slidesToShow: 7,
          slidesToScroll: 1,
          adaptiveHeight: true,
          cancelable: false,
          centerMode: true,
          responsive: [
            {
              breakpoint: 1770,
              settings: {
                arrows: true,
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1441,
              settings: {
                arrows: true,
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1400,
              settings: {
                arrows: true,
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1125,
              settings: {
                arrows: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                centerMode: true,
                variableWidth: true,
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 426,
              settings: {
                arrows: true,
                centerMode: false,
                variableWidth: true,
                slidesToShow: 1,
                centerMode: true,
              },
            },
          ],
        },
        carouselOptions: {},
      };
    },
    methods: {
      ...mapActions("gallery", ["getGallery"]),

      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      onClickLink: function (id, idx) {
        const path = { path: "/gallery", hash: "gallery", query: { filter: id, id: idx } };
        if (!this.openSelf) {
          this.$router.push(path);
          return;
        }
        const link = this.$router.resolve(path);
        window.open(link.href, "_blank");
      },

      getLang: async function () {
        if (store.getters["iflanguage"]) {
          this.language = await store.getters["localeLang"];
        }
      },
    },
    computed: {
      initData: async function () {
        await this.getLang();
        this.lang = localStorage.getItem("user-language");
      },
    },
    async beforeMount() {
      this.data = await this.getGallery();
      this.data.categories.reverse();
    },
  };
</script>

<template>
  <section class="section section-carousel" id="gallery" v-bind="initData">
    <div class="section-carousel-container" v-if="data && language">
      <div class="section-carousel-wrap">
        <div class="section-carousel-title">
          <h2 class="section-carousel-title-top third-font hidden">{{ language.words.ourGallery.title }}</h2>
          <h2 class="section-carousel-title-sub sec-font">{{ language.words.ourGallery.subTitle }}</h2>
        </div>
        <VueSlickCarouselc class="section-carousel-filter-slide slid-gallery" v-if="data.categories" v-bind="settings">
          <template #prevArrow="">
            <div class="custom-arrow">
              <iconic name="prevArrowTransparent" />
            </div>
          </template>
          <div class="section-carousel-filter-category" v-for="(cat, cidx) in data.categories" :key="cidx">
            <a class="section-carousel-filter-link" @click="onClickLink(cat._id, cidx)" inline-b>
              <div :class="'section-carousel-filter-card '" prevent>
                <div :class="`section-carousel-filter-img-wrap ${cat.isNewVideo ? 'section-new' : ''}`">
                  <img loading="lazy" :src="urlCloudFront(cat.pictureLink)" :alt="'Galeria de sesiones fotograficas - ' + cat.alt" class="section-carousel-filter-img" />
                </div>
                <div class="section-carousel-filter-img-tittle" ellipsis>{{ cat.hashtagText[lang] }}</div>
              </div>
            </a>
          </div>
          <template #nextArrow>
            <div class="custom-arrow">
              <iconic name="nextArrowTransparent" />
            </div>
          </template>
        </VueSlickCarouselc>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .slid-gallery {
    .slick-slide {
      padding: 10px;
    }
    .slick-center {
      margin-left: 30px;
      margin-right: 25px;
      left: -81px;
    }
    .slick-track {
      display: flex;
      height: 400px;
      padding-top: 35px;
      .slick-current {
        border-radius: 12px;
        .section-carousel-filter-card {
          width: 276px;
          height: 276px;
          margin-left: -30px;
          transition: ease-in-out;
          transform: scaleX(1);
          border-radius: 12px;
        }
        .section-carousel-filter-img-wrap {
          width: 100%;
          height: 100%;
          border-radius: 12px;
        }
        .section-carousel-filter-link {
          margin-top: -28px;
        }
      }
    }
    @keyframes arrowHover {
      0% {
        color: #bd0909;
      }
      50% {
        color: transparent;
      }
      100% {
        color: #bd0909;
      }
    }
    .custom-arrow {
      width: 50px;
      height: 70px;
      margin: 5px;
      &:hover {
        .iconic.hovered {
          animation: arrowHover 0.3s ease-in-out;
        }
      }
      .iconic {
        font-size: 45px;
        color: #bd0909;
        .arrow {
          color: white;
        }
      }
    }
    .slick-arrow {
      top: 100%;
    }
    .slick-prev {
      left: 33%;
    }
    .slick-next {
      right: 28%;
    }
    @media only screen and (min-width: 426px) and (max-width: 767px) {
      .slick-prev {
        left: 35%;
      }
      .slick-next {
        right: 35%;
      }
      .slick-center {
        left: -82px;
      }
      .slick-track {
        .slick-current {
          .section-carousel-filter-card {
            margin-left: -30px;
          }
        }
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .slick-prev {
        left: 41%;
      }
      .slick-next {
        right: 41%;
      }
      .slick-center {
        left: -10px;
      }
      .slick-track {
        .slick-current {
          .section-carousel-filter-card {
            margin-left: -30px;
          }
        }
      }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      .slick-prev {
        left: 42%;
      }
      .slick-next {
        right: 42%;
      }
      .slick-center {
        left: -10px;
      }
      .slick-track {
        .slick-current {
          .section-carousel-filter-card {
            margin-left: -30px;
          }
        }
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 2560px) {
      .slick-prev {
        left: 46%;
      }
      .slick-next {
        right: 46%;
      }
      .slick-center {
        left: -10px;
      }
      .slick-track {
        .slick-current {
          .section-carousel-filter-card {
            margin-left: -30px;
          }
        }
      }
    }
  }

  .section-carousel {
    padding-top: 0 !important;
    &-container {
      padding: 55px 0;
      text-align: center;
      background: rgba(189, 9, 9, 0.04);
    }
    &-header {
      height: 240px;
    }
    &-title {
      &-top {
        font-size: 20px;
        font-weight: bold;
        color: $primary_color_50;
      }
      &-sub {
        font-size: 42px;
        color: $primary_color;
        font-weight: normal;
      }
    }
    &-filter {
      &-category {
        height: 380px;
      }
      &-link {
        cursor: pointer;
        height: 222px;
        width: 222px;
        border-radius: 12px;
      }

      &-img-wrap {
        margin-top: 10px;
        width: 222px;
        height: 222px;
        overflow: hidden;
        position: relative;
        &::after {
          position: absolute;
          visibility: hidden;
          top: 26px;
          right: -25px;
          content: "New";
          font-size: 10px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          line-height: 20px;
          transform: rotate(45deg);
          width: 120px;
          background: $primary_color;
          box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        }
        &.section-new {
          position: relative;

          &::after {
            visibility: visible;
          }
        }
      }
      &-img {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      &-card,
      &-img-wrap {
        border-radius: 12px;
      }
      &-img-tittle {
        border-bottom-left-radius: 12px;
        font-size: 20px;
        margin-top: 7px;
        font-family: $sec_font;
        text-align: center;
        color: white;
        position: relative;
        top: -60px;
        background-image: linear-gradient(0deg, rgba(22, 22, 22, 1) 22%, rgba(15, 15, 15, 0) 81%, rgba(19, 19, 19, 0.04525560224089631) 100%);
        border-bottom-right-radius: 12px;
        padding-bottom: 15px;
        padding-top: 15px;
      }
      .router-link-exact-active &-card {
        background-color: $primary_color;
        color: white;
      }
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      height: 100%;
      width: 70px;
    }
    .slick-prev::before,
    .slick-next::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
    }
    .slick-prev {
      height: 70px;
    }
    .slick-next {
      height: 70px;
    }

    @media (min-width: 769px) {
      padding-top: 0 !important;
      &-container {
        padding: 85px 0;
      }
      &-title {
        &-top {
          font: {
            size: 30px;
            weight: bold;
          }
        }
        &-sub {
          font-size: 42px;
          font-style: normal;
          font-weight: 400;
        }
      }
      &-filter {
        &-slide {
          margin: 0 60px;
        }
      }
    }

    @media (min-width: $desktop_width) {
      &-title {
        &-top {
          font: {
            size: 30px;
            weight: bold;
          }
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-filter {
        &-slide {
          margin: 0 60px;
        }
      }
    }
  }
</style>
