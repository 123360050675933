<template>
  <div class="section-services-icons-icon">
    <router-link :to="getRouterLink(video)" block>
      <div class="wrapper-btn" v-if="video">
        <iconic name="btn_play" />
      </div>
      <div :class="`wrap pointer ${video ? 'blur' : ''}`">
        <div class="figure">
          <img :src="icon" :alt="`${locale.alt.Icon}  ${text}`"/>
        </div>
        <div class="text">
          <h3>{{ text }}</h3>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import iconic from "@/plugins/iconic/iconic.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    iconic,
  },
  props: {
    icon: String,
    text: String,
    video: String,
  },
  computed: {
    ...mapGetters(["newLocale"]),
    locale(){
      return this.newLocale || {}
    },
  },
  methods: {
    hoverImage: function (flag) {
      let newNameImg = this.$refs.imagen.src.split("/").pop().split(".")[0];
      if (flag) newNameImg += "_hover";
      else if (newNameImg.endsWith("_hover")) newNameImg = newNameImg.slice(0, -6);
      this.$refs.imagen.src = `https://new-web-site-bucket.s3.amazonaws.com/dev/services/${newNameImg}.svg`;
    },
    getRouterLink(video) {
      if (video) {
        return {
          path: "watch",
          query: { v: this.urlEncodeB64(video), backto: "/" },
        };
      } else {
        return "#";
      }
    },
    urlEncodeB64: function (url) {
      return btoa(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-services-icons-icon {
  position: relative;
  &:hover {
    .blur {
      filter: blur(4px);
    }
    .iconic {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .text {
    color: #5b5957;
  }
  .pointer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .iconic {
    display: none;
    position: absolute;
    font-size: 40px;
    z-index: 20;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .icon-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--image);
    background-position: center center;
  }
  .figure {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    img {
      width: 80px;
    }
  }
  .iconic {
    color: white;
  }
}
</style>
