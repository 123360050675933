<template>
  <section class="home-section" v-bind="initData">
    <HomeWelcome />
    <HomeSlide />
    <HomeOurCompany />
    <HomeServices :to="this.to" />
    <VueSlickCarouselComp />
    <SliderVideosHome />
    <TestimonialsSection :to="this.to" />
    <PresentSection />
    <LocationsSection />
    <router-view></router-view>
  </section>
</template>
<script>
// @ is an alias to /src
import store from "../../store/index.js";
import HomeOurCompany from "@/components/web/HomeOurCompany.vue";
import HomeTraining from "@/components/web/HomeTraining.vue";
import HomeServices from "@/components/web/HomeServices.vue";
import PresentSection from "@/components/web/PresentSection.vue";
import LocationsSection from "@/components/web/LocationsSection.vue";
import VueSlickCarouselComp from "@/components/web/CarouselGalleryComp.vue";
import SliderVideosHome from "@/components/web/SliderVideosHome.vue";
import TestimonialsSection from "@/components/web/TestimonialsSection.vue";
import HomeWelcome from "@/components/web/HomeWelcome.vue";
import HomeSlide from "@/components/web/HomeSlide.vue";

export default {
  components: {
    HomeOurCompany,
    HomeTraining,
    HomeServices,
    PresentSection,
    LocationsSection,
    VueSlickCarouselComp,
    SliderVideosHome,
    HomeWelcome,
    TestimonialsSection,
    HomeSlide,
  },
  data: function () {
    return {
      lang: null,
      locale: null,
      to: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.to = to;
    });
  },
  methods: {
    getLang: async function () {
      if (store.getters["iflanguage"]) {
        this.lang = await store.getters["dataLang"];
        this.locale = await store.getters["localeLang"];
      }
    },
  },
  computed: {
    initData: async function () {
      await this.getLang();
    },
  },
};
</script>
