<script>
  import SitesCarouselDesktop from "./SitesCarouselDesktop.vue";
  import SitesCarouselMobile from "./SitesCarouselMobile.vue";
  import { mapGetters } from "vuex";

  export default {
    props: ["title", "description", "playText", "sites"],
    components: { SitesCarouselDesktop, SitesCarouselMobile },
    data() {
      return {
        swiperSettings: {
          slidesPerView: "auto",
          pagination: {
            clickable: true,
          },
          simulateTouch: false,
          allowTouchMove: false,
        },
        carousel3dConfig: {
          autoplay: false,
          loop: true,
          height: 480,
          width: 253,
          space: 300,
          perspective: 10,
        },
      };
    },
    computed: {
      ...mapGetters(["screen"]),
      isLessthan768() {
        return this.screen.width <= 768;
      },
    },
  };
</script>

<template>
  <div class="sites" id="sites">
    <div class="sites__infoContainer">
      <div class="sites__title">
        <h2 class="sites__location">{{ title }}</h2>
      </div>
      <div class="sites__description">
        <p>{{ description }}</p>
      </div>
      <div class="sites__buttonContainer">
        <div class="sites__playDescription">
          <p>{{ playText }}</p>
        </div>
      </div>
    </div>
    <div class="sites__carouselContainer">
      <sites-carousel-desktop v-if="!isLessthan768" :data="sites" :swiperSettings="swiperSettings" />
      <sites-carousel-mobile v-else :data="sites" :carouselConfig="carousel3dConfig" />
    </div>
  </div>
</template>

<style lang="scss">
  .sites {
    margin: 50px $space-0;
    @include Flex(column, space-between);
    width: 100%;
    height: 100%;
    &__location{
      font-size: 40px;
    }
    &__infoContainer {
      @include Flex(column, space-between, center);
      gap: $space-20;
      width: 100%;
      height: 100%;
    }
    &__carouselContainer {
      width: 100%;
      height: 100%;
    }
    &__title,
    &__playDescription {
      color: $primary-color;
      font-family: $sec_font;
    }
    &__title {
      font-size: 40px;
      font-weight: 600;
    }
    &__playDescription {
      font-size: 25px;
      font-weight: 400;
      text-align: center;
    }
    &__description {
      font-size: 18px;
      padding: $space-0 $space-16;
      text-align: center;
    }
    @media (min-width: 768px) {
      &__carouselContainer {
        margin-top: $space-20;
      }
      &__description {
        padding: unset;
      }
    }
    @media (min-width: 1024px) {
      @include Flex(row, space-evenly);
      &__title,
      &__playDescription {
        text-align: start;
      }
      &__infoContainer {
        align-items: flex-start;
        width: 30%;
      }
      &__carouselContainer {
        margin-top: unset;
        width: 60%;
      }
      &__description {
        text-align: start;
      }
    }
    @media (min-width: 1440px) {
      justify-content: space-between;
    }
  }
</style>
