<script>
  import { Carousel3d, Slide } from "vue-carousel-3d";
  import loadChunks from "../../utils/loadVideoChunks";

  export default {
    components: { Carousel3d, Slide },
    props: {
      data: {
        type: Array,
        required: true,
      },
      carouselConfig: {
        type: Object,
      },
    },
    data() {
      return {
        videoPlayCurrent: null,
        timeoutId: null,
      };
    },
    methods: {
      handleClick(index) {
        this.$refs.mycarousel.goSlide(index);
        this.setNewVideoPlay(index, true);
      },
      setNewVideoPlay(index, isCLick = false) {
        const container = this.$refs.mycarousel.$el;
        const videos = container.querySelectorAll(".sitesCarouselMobile__video");
        const videoPlay = `sitesCarouselMobile__video--${index}`;

        videos.forEach((video) => {
          const isPlayVideo = video.classList.contains(videoPlay);
          if (isPlayVideo && isCLick) {
            clearTimeout(this.timeoutId);
            video.classList.add("sitesCarouselMobile__video--play");
            this.timeoutId = setTimeout(() => {
              video.controls = true;
            }, 500);
            video.play();
          } else {
            video.classList.remove("sitesCarouselMobile__video--play");
            video.pause();
            video.controls = false;
          }
        });
      },
      handleBeforeSlideChange(index) {
        this.setNewVideoPlay(index, false);
        this.videoPlayCurrent = index;
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.data.forEach((item, index) => {
          const videoElement = this.$refs[`video-${index}`][0];
          loadChunks(videoElement, item.videochunks, item.videoOriginal);
        });
      });
    },
  };
</script>

<template>
  <carousel-3d
    ref="mycarousel"
    :key="data.length"
    :autoplay="carouselConfig.autoplay"
    :loop="carouselConfig.loop"
    :height="carouselConfig.height"
    :width="carouselConfig.width"
    :space="carouselConfig.space"
    :perspective="carouselConfig.perspective"
    class="sitesCarouselMobile__carousel3d"
    @before-slide-change="handleBeforeSlideChange">
    <slide v-for="(item, i) in data" :index="i" :key="i" class="sitesCarouselMobile__slide">
      <div class="sitesCarouselMobile__videoContainerMobile">
        <video :class="`sitesCarouselMobile__video sitesCarouselMobile__video--${i}`" :ref="`video-${i}`" playsinline :poster="item.imagePoster" controls></video>
        <div class="sitesCarouselMobile__imgPoster" @touchstart="() => handleClick(i)" @click="() => handleClick(i)">
          <img :src="item.imagePoster" :alt="item.alt" class="sitesCarouselMobile__img" />
          <div class="sitesCarouselMobile__gradient">
            <div class="sitesCarouselMobile__playIconContainer">
              <iconic name="play" class="sitesCarouselMobile__playIcon" />
            </div>
          </div>
        </div>
      </div>
      <div class="sitesCarouselMobile__cityName">{{ item.city }}</div>
    </slide>
  </carousel-3d>
</template>

<style lang="scss">
  .sitesCarouselMobile {
    &__videoContainerMobile {
      @include Flex();
      position: relative;
      width: 100%;
      height: 94%;
    }
    &__imgPoster {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 1;
      pointer-events: auto;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 10px $radius-0 $radius-0;
    }
    &__playIconContainer {
      position: absolute;
      @include Flex();
      width: 30px;
      height: 30px;
      background-color: $primary-color;
      border-radius: 50%;
      bottom: 6px;
      right: 6px;
    }
    &__gradient {
      @include Flex(row, flex-end, center);
      width: 100%;
      height: 40px;
      padding: $space-0 10px 10px $space-0;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
    &__playIcon {
      font-size: 16px;
      margin-left: $space-2;
      color: #fff;
      z-index: 1;
    }
    &__video {
      opacity: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      border-radius: 10px 10px $radius-0 $radius-0;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      pointer-events: none;
      &--play {
        opacity: 1;
        z-index: 1;
        pointer-events: auto;
        ~ .sitesCarouselMobile__imgPoster {
          opacity: 0;
          pointer-events: none;
        }
      }
      &:fullscreen {
        object-fit: contain;
      }
      &::-webkit-media-controls-fullscreen-button {
        display: none;
      }
    }
    &__cityName {
      color: $primary-color;
      font-family: $sec_font;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 30px;
      border-radius: $radius-0 $radius-0 10px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }
    &__carousel3d {
      overflow: visible !important;
    }
    &__slide {
      @include Flex(column);
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: none;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
</style>
