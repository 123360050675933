<template>
  <div
    :class="{ loading: loadImg, testimonials__model__skeleton: loadImg, testimonials__model__media: true }"
    @click="manageVideo()"
    @mouseenter="togglePoster($event, true)"
    @mouseleave="togglePoster($event, model.playingVideo)"
    v-if="model">
    <div class="testimonials__model" v-if="!model.playingVideo && !loadImg">
      <div class="testimonials__model-container-name">
        <div class="testimonials__model-name">
          <h3 class="testimonials__model-name-text">{{ model.alt }}</h3>
        </div>
        <div class="container-lines__names">
          <span class="lines__names"></span>
          <span class="lines__names"></span>
          <span class="lines__names"></span>
        </div>
      </div>
      <button class="btn-play">
        <img src="../../assets/img/btn-play.svg" alt="Boton inicio de contenido" />
      </button>
      <p class="testimonials__model-gala">{{ model.nameTestimonial }}</p>
    </div>
    <img v-show="!model.showPoster && !model.playingVideo" :src="urlCloudFront(model.imageLink)" :alt="model.nameTestimonial" loading="lazy" @load="() => onLoad('onloadImage')" />
    <video @loadeddata="() => onLoad('onloadVideo')" class="video__testimonial" v-show="model.showPoster" :src="urlCloudFront(model.videoLinkDesk)" ref="videoTestimonials" :id="'video-' + model._id">
      <source :src="urlCloudFront(model.videoLink)" media="(min-width: 768px)" type="video/mp4" :poster="model.poster" />
    </video>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loadImg: true,
        loadVideo: false,
      };
    },
    props: ["model", "currentVideo"],
    mounted() {
      document.addEventListener("touchstart", this.handleTouchStart);
    },
    beforeDestroy() {
      document.removeEventListener("touchstart", this.handleTouchStart);
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      togglePoster(event, flag) {
        if (this.isMobileDevice()) {
          this.handleTouchStart(event);
        } else if (this.loadVideo) {
          this.model.showPoster = flag;
        }
      },
      isMobileDevice() {
        const userAgent = navigator.userAgent.toLowerCase();
        return /mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
      },
      handleTouchStart(event) {
        const el = this.$el;
        if (!(el && el.contains(event.target))) {
          const video = this.$refs.videoTestimonials;
          video.pause();
          this.model.showPoster = false;
          this.model.playingVideo = false;
          if (video.currentTime > 0) {
            video.currentTime = 0;
          }
        }
      },
      manageVideo() {
        const video = this.$refs.videoTestimonials;

        this.model.playingVideo = true;
        this.model.showPoster = true;
        video.controls = true;
        if (!this.currentVideo || this.currentVideo !== this.model._id) {
          this.$emit("changeCurrentVideo", this.model._id);
          document.querySelectorAll(".video__testimonial").forEach((video) => {
            video.pause();
          });
          video.play();
        }
      },
      onLoad(flag) {
        if (flag === "onloadImage") {
          this.loadImg = false;
        } else if (flag === "onloadVideo") {
          this.loadVideo = true;
        }
      },
    },
  };
</script>
<style lang="scss">
  .testimonials__model {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: background ease-in 0.5s;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 1.56%, rgba(0, 0, 0, 0.19) 10.94%, rgba(0, 0, 0, 0.45) 43.96%, rgba(0, 0, 0, 0.7) 100%);
    &:hover {
      .testimonials__model-name {
        background-color: #bd0909;
      }
    }
    &-img {
      background-color: $primary-color;
      height: 100%;
    }
    .btn-play {
      cursor: pointer;
      background: transparent;
      border: none;
      margin: auto;
      display: block;
    }
    .testimonials__model-container-name {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .container-lines__names {
      height: 40px;
      z-index: 300;
      display: flex;
      animation: fadeIn 0.2s ease-in-out;
    }
    .lines__names {
      display: block;
      width: 10px;
      height: 41px;
      background-color: #bd0909;
      margin: 15px 5px;
      transform: skew(-47deg);
    }
    .testimonials__model-name {
      max-width: 100%;
      height: 40px;
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
      margin-top: 33px;
      padding: 5px 33px 10px 28px;
      box-shadow: 0px 4px 4px rgba(255, 54, 171, 0.28);
      background-color: #bd0909b2;
      color: #fff;
      .testimonials__model-name-text {
        padding-right: 20px;
        font-size: 22px;
        width: auto;
        font-family: $sec_font;
      }
    }
    .testimonials__model-gala {
      position: absolute;
      width: 100%;
      top: 83%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(195, 195, 195, 0));
      bottom: 0;
      z-index: 100;
      color: #fff;
      padding: 30px;
      font-weight: normal;
      font-family: BebasKai, sans-serif;
      font-size: 22px;
    }
    &__skeleton {
      width: 100%;
      height: 100%;
    }
    &__media {
      position: relative;
    }
  }

  @include Desktop {
    .testimonials__model {
      height: 99.8%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 1.56%, rgba(0, 0, 0, 0.19) 10.94%, rgba(0, 0, 0, 0) 60.94%, rgba(0, 0, 0, 0.15) 91.15%, rgba(0, 0, 0, 0.7) 100%);
      &:hover {
        .container-lines__names {
          display: flex;
        }
        .btn-play {
          display: block;
        }
      }
      .container-lines__names {
        display: none;
      }
      .btn-play {
        display: none;
      }
      .testimonials__model-gala {
        top: initial;
      }
    }
  }
</style>
